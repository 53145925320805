import { useState } from "react";
import "./App.css";

function App() {
  const [count, setCount] = useState(0);

  const handleGet = () =>
    fetch(`https://api.quest-capybara-blackwall.online/api/privet`)
      .then((r) => r.json())
      .then((r) => alert(JSON.stringify(r)));

  const handlePost = () =>
    fetch(`https://api.quest-capybara-blackwall.online/api/privet`, {
      method: "post",
    })
      .then((r) => r.json())
      .then((r) => alert(JSON.stringify(r)));

  return (
    <>
      <div>
        <a href="https://vitejs.dev" target="_blank">
          <img src={`/vite.svg`} className="logo" alt="Vite logo" />
        </a>
      </div>
      <h1>Vite + React</h1>
      <div className="card">
        <button onClick={() => setCount((count) => count + 1)}>
          count is {count}
        </button>
        <p>
          Edit <code>src/App.tsx</code> and save to test HMR
        </p>
        <button onClick={handleGet}>GET</button>
        <button onClick={handlePost}>POST</button>
      </div>
      <p className="read-the-docs">
        Click on the Vite and React logos to learn more
      </p>
    </>
  );
}

export default App;
